import { Link } from "@mui/material";
import React from "react";

const Today = (events) => {
  return (
    <div>
      <ul className="monthlyevents-list">
        {events?.events?.map((event, index) => {
          return (
            <li key={index}>
              <Link to="/">{event?.title}</Link>
              <span>{event?.start ? event?.start : event?.start_date}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Today;

import React from "react";
import "./dayschedule.css";
import { Box } from "@mui/material";

function Dayschedule({ dailySchedule }) {
  return (
    <>
      <React.Fragment>
        <div className="text-center h-100 pt-0 panchang">
          <div className="panchang-content2">
            <Box
              sx={{
                p: 3,
                maxHeight: "400px",
                overflowY: "auto",
                width: "100%",
              }}
            >
              <ul className="dayschedulel-list">
                {dailySchedule?.map((Schedule, i) => (
                  <li key={i}>
                    <span>{Schedule.time}</span>
                    {Schedule.title}
                  </li>
                ))}
              </ul>
            </Box>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}

export default Dayschedule;

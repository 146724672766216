import React from "react";
import "./panchang.css";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { capitalize, initial, lowerCase } from "lodash";
import { LineWeight } from "@mui/icons-material";

function Panchang() {
  return (
    <>
      <React.Fragment>
        <div className="text-center h-100 pt-0 panchang">
          {/* <div className="title-bg">
            <h2>Panchangam</h2>
          </div> */}
          <div className="panchang-content2">
            <Box sx={{ p: 3, maxHeight: "400px", overflowY: "auto" }}>
              <div className="pancgan-table">
                <iframe
                  id="IDpanchang"
                  src="https://www.mypanchang.com/newsite/compactfeed.php?locid=4758023&mode=full&pt=12pt"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  scrolling="auto"
                ></iframe>
              </div>
            </Box>
          </div>
        </div>
      </React.Fragment>
      <div className="d-flex justify-content-end">
        <Link
          className="btn text-white py-2 m-2 px-4 btn-right"
          to="/fullpanchang"
        >
          Full Panchang
        </Link>
      </div>
    </>
  );
}

export default Panchang;

import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import "./servicesattemple.css";
import { Link } from "react-router-dom";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
function ServicesAtTemple() {
  const [serviceCategories, setServiceCategories] = useState();
  const getServiceCategories = async () => {
    const response = await getApi(apiEndPoints.allServices);
    if (response && response.status === 200) {
      setServiceCategories(response?.data?.data);
    }
  };

  useEffect(() => {
    getServiceCategories();
  }, []);
  // function handleDownloadClick() {
  //   ReactGA.event({
  //     category: 'Downloads',
  //     action: 'Download File',
  //     label: 'File Name.pdf' // Replace with the name of the file being downloaded
  //   });
  // }
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Services at Temple
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Services at Temple</li>
        </ol>
      </div>
      <div className="container-fluid py-5">
        <div className="container pt-4">
          <div className="text-center">
            <h6 className="section-title text-center text-primary text-uppercase title-tl">
              Services at
            </h6>
            <h1 className="mb-3">
              <span className="text-secundary">Temple</span>
            </h1>
          </div>
          <div className="container py-4">
            <div className="row g-4">
              {serviceCategories &&
                serviceCategories.map((item) => (
                  <div className="col-lg-3 col-md-6">
                    <Link
                      className="service-item d-block rounded text-center h-100 p-3"
                      to={`/services/${item.slug}`}
                    >
                      <img
                        className="img-fluid rounded mb-3"
                        src={item.image}
                        alt={item.title}
                      />
                      <h4 className="mb-0">{item.title}</h4>
                      {/* <a href="#!">
                        <i className="fa fa-download" onClick={(e) => downloadImg(item.image, e)} />
                      </a> */}
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ServicesAtTemple;

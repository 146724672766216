import React from "react";
import "./panchangam.css";
import Dayschedule from "../Dayschedule/Dayschedule";
import News from "../newsevents/News";
import Events from "../newsevents/Events";
import Panchang from "../panchang/Panchang";
import moment from "moment";
import MonthlyEvents from "../monthlyevents/MonthlyEvents";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Link } from "@mui/material";
function NewsEventsSection(props) {
  const {
    dailySchedule,
    dailyPanchangam,
    dailyNews,
    allEvents,
    templeConfig,
    getSubsectionStatus,
    upcomingEvents,
  } = props;

  return (
    <>
      <div className="container-fluid about-temple-bg panchang-btm">
        <div className="container panchang-top-pad">
          <div className="row g-4 mb-4">
            {getSubsectionStatus(
              "Services",
              "Daily Pooja Schedule",
              templeConfig
            ) && (
              <div
                className={
                  getSubsectionStatus(
                    "Event and Activities",
                    "BalGokul",
                    templeConfig
                  )
                    ? "col-lg-4 col-md-4"
                    : "col-lg-12 col-md-4"
                }
              >
                <div className="text-center h-100 pt-0 panchang">
                  <div className="title-bg">
                    <h2>Day Schedule</h2>
                  </div>
                  <div className="sub-title-bg d-flex justify-content-around">
                    <div>
                      <Link to="/">
                        <KeyboardDoubleArrowLeftIcon className="arw-btn" />
                      </Link>
                    </div>
                    <div>
                      <h2>
                        {moment()
                          .tz("America/New_York")
                          .format("dddd, MMMM Do YYYY")}
                      </h2>
                    </div>
                    <div>
                      <Link to="/">
                        {" "}
                        <KeyboardDoubleArrowRightIcon className="arw-btn" />
                      </Link>
                    </div>
                  </div>
                  <div className="panchang-content">
                    <Dayschedule dailySchedule={dailySchedule} />
                  </div>
                </div>
              </div>
            )}
            {getSubsectionStatus(
              "Event and Activities",
              "BalGokul",
              templeConfig
            ) && (
              <div
                className={
                  getSubsectionStatus(
                    "Services",
                    "Daily Pooja Schedule",
                    templeConfig
                  )
                    ? "col-lg-8 col-md-8"
                    : "col-lg-12 col-md-8"
                }
              >
                <div className="text-center h-100 pt-0 panchang">
                  <MonthlyEvents upcomingEvents={upcomingEvents} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container-fluid panchang-btm">
        <div className="container panchang-top-pad">
          <div className="row g-4 mb-5">
            {getSubsectionStatus("Admin", "News", templeConfig) && (
              <div className="col-lg-6 col-md-6">
                <div className="text-center h-100 pt-0 panchang">
                  <News dailyNews={dailyNews} />
                </div>
              </div>
            )}

            <div
              className={
                getSubsectionStatus("Admin", "News", templeConfig)
                  ? "col-lg-6 col-md-6"
                  : "col-lg-12 col-md-6"
              }
            >
              <div className="text-center h-100 pt-0 panchang">
                <Events allEvents={allEvents} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NewsEventsSection;

import * as React from "react";
import "./CalendarView.css";
import { useState, useEffect } from "react";
import { Link } from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { apiEndPoints } from "../../../../ApiServices/config";
import { postApi } from "../../../../ApiServices/api";

// Custom event component
const CustomEvent = ({ event, currentView }) => {
  const startTime = moment(event.start).format("HH:mm");
  const endTime = moment(event.end).format("HH:mm");
  // Only show start and end time in the month view
  if (currentView === "month") {
    return (
      <div className="custom-event">
        <div className="event-time">
          {startTime} - {endTime}
        </div>
        <strong>{event.title}</strong>
      </div>
    );
  }
  // Default rendering for other views (week, day)
  return (
    <span>
      <strong>{event.title}</strong>
    </span>
  );
};

function CalenderView() {
  const [events, setEvents] = useState([]);
  const [currentView, setCurrentView] = useState("month"); // Track the current view
  const localizer = momentLocalizer(moment);

  // Fetch events and set them in state
  const viewShedule = async () => {
    const response = await postApi(apiEndPoints.getUpcomingEvents);
    console.log("response :- ", response.data);

    const mergedArray = [
      ...(response.data?.today || []).map((event) => ({
        ...event,
        allDay: false,
        start: new Date(event.start),
        end: new Date(event.end),
      })),
      ...(response.data?.weekly || []).map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      })),
      ...(response.data?.monthly || []).map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      })),
    ];
    console.log("mergedArray:- ", mergedArray);

    setEvents(mergedArray);
  };

  // Run once when the component mounts
  useEffect(() => {
    viewShedule();
  }, []);

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">CalendarView</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Events & Activities</Link>
          </li>
          <li className="breadcrumb-item active">Calendar View</li>
        </ol>
      </div>
      <div className="container py-5">
        <div style={{ height: 500, width: "100%" }} className="rbc-calendar">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            defaultDate={new Date()}
            defaultView="month"
            onView={(view) => setCurrentView(view)} // Track current view
            components={{
              event: (props) => (
                <CustomEvent {...props} currentView={currentView} />
              ), // Pass current view to the event component
            }}
          />
        </div>
      </div>
    </>
  );
}

export default CalenderView;

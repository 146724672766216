import * as React from "react";
import { Link } from "react-router-dom";
import "./prasadam.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, CardHeader } from "react-bootstrap";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  DialogTitle,
} from "@mui/material";
import CateringCart from "../../components/common/Basket/CateringBasket";
import { useDispatch, useSelector } from "react-redux";
import { updatePrasadamCart } from "../../actions";
import toast from "react-hot-toast";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import CommoQuantityInput from "../../components/common/inputs/CommonQuantityInput";
import { Controller, useForm } from "react-hook-form";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Add, Remove } from "@mui/icons-material";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function Catering() {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const prasadamArr = useSelector((state) => state.prasadamArr);
  const dispatch = useDispatch();

  const {
    watch,
    control,
    formState: { errors },
  } = useForm({});

  const getCateringItems = async () => {
    const res = await getApi(apiEndPoints?.getAllCateringItems);
    setData(res?.data?.data);
  };

  React.useEffect(() => {
    getCateringItems();
  }, []);
  console.log(watch());
  const handleAddItem = (item) => {
    const existingCartItem = prasadamArr.find(
      (cartItem) => cartItem._id === item._id
    );

    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        itemQuantitiy: item?.itemQuantitiy,
      };
      dispatch(updatePrasadamCart(updatedItem));
      toast.success("Your puja/donation is updated");
    } else {
      const newItem = {
        ...item,
        itemName: item?.itemName,
        itemQuantitiy: item?.itemQuantitiy,
        _id: item?._id,
        amount: item?.amount,
      };
      dispatch(updatePrasadamCart(newItem));
      toast.success("Your puja/donation added successfully on puja basket");
    }
  };

  console.log(watch());
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Catering</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Catering</li>
        </ol>
      </div>

      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Services
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Catering</span>
          </h1>
        </div>

        <div className="row">
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <div className="col">
                <nav className="navbar">
                  <form
                    className="container-fluid justify-content-start ng-untouched ng-pristine ng-valid"
                    noValidate
                  >
                    <button
                      className="tag-btn btn-outline-info me-2"
                      type="button"
                    >
                      Sweet
                    </button>
                    <button
                      className="tag-btn btn-outline-secondary me-2"
                      type="button"
                    >
                      Snack
                    </button>
                    <button
                      className="tag-btn btn-outline-primary me-2"
                      type="button"
                    >
                      Tiffin
                    </button>
                    <button
                      className="tag-btn btn-outline-warning me-2"
                      type="button"
                    >
                      Rice
                    </button>
                    <button
                      className="tag-btn btn-outline-dark me-2"
                      type="button"
                    >
                      South Indian
                    </button>
                    <button
                      className="tag-btn btn-outline-primary me-2"
                      type="button"
                    >
                      North Indian
                    </button>
                    <button
                      className="tag-btn btn-outline-success me-2"
                      type="button"
                    >
                      Pickles / Pachidi
                    </button>
                    <button
                      className="tag-btn btn-outline-secondary me-2"
                      type="button"
                    >
                      Beverages
                    </button>
                    <button
                      className="tag-btn btn-outline-danger me-2"
                      type="button"
                    >
                      Greens
                    </button>
                    <button
                      className="tag-btn btn-outline-info me-2"
                      type="button"
                    >
                      Others
                    </button>
                  </form>
                </nav>
              </div>

              <TableContainer>
                {data.map((row) => (
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    {/* <TableHead>
                      <TableRow>
                        <TableCell>Image</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Small Tray</TableCell>
                        <TableCell>Medium Tray</TableCell>
                        <TableCell>Large Tray</TableCell>
                      </TableRow>
                    </TableHead> */}
                    <TableBody>
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" width="12%">
                          <CardMedia
                            component="img"
                            height="128"
                            image={row?.image}
                            alt="Paella dish"
                          />
                        </TableCell>
                        <TableCell width="14%">{row?.title}</TableCell>
                        <TableCell width="34%">
                          <Typography
                            className="des-content"
                            variant="body2"
                            color="text.secondary"
                            dangerouslySetInnerHTML={{
                              __html: row?.description,
                            }}
                          ></Typography>
                        </TableCell>
                        <TableCell width="12%">
                          <Typography className="tray-align">
                            Small Tray
                          </Typography>
                          Price: $75
                          <div className="qty-align">
                            <IconButton aria-label="expand row" size="small">
                              <Remove className="decrease-btn" />
                            </IconButton>
                            0
                            <IconButton aria-label="expand row" size="small">
                              <Add className="increase-btn" />
                            </IconButton>
                          </div>
                          <Button
                            variant="contained"
                            color="primary"
                            className="qty-btn-add"
                            onClick={() => {}}
                          >
                            Add
                          </Button>
                        </TableCell>
                        <TableCell width="12%">
                          <Typography className="tray-align">
                            Medium Tray
                          </Typography>
                          Price: $100{" "}
                          <div className="qty-align">
                            <IconButton aria-label="expand row" size="small">
                              <Remove className="decrease-btn" />
                            </IconButton>
                            0
                            <IconButton aria-label="expand row" size="small">
                              <Add className="increase-btn" />
                            </IconButton>
                          </div>
                          <Button
                            variant="contained"
                            color="primary"
                            className="qty-btn-add"
                            onClick={() => {}}
                          >
                            Add
                          </Button>
                        </TableCell>
                        <TableCell width="12%">
                          <Typography className="tray-align">
                            Large Tray
                          </Typography>
                          Price: $150{" "}
                          <div className="qty-align">
                            <IconButton aria-label="expand row" size="small">
                              <Remove className="decrease-btn" />
                            </IconButton>
                            0
                            <IconButton aria-label="expand row" size="small">
                              <Add className="increase-btn" />
                            </IconButton>
                          </div>
                          <Button
                            variant="contained"
                            color="primary"
                            className="qty-btn-add"
                            onClick={() => {}}
                          >
                            Add
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}

                {/* <Grid container spacing={2} p={3}>
                {data.map((row) => (
                  <Grid item xs={4} key={row.id}>
                    <Card sx={{ maxWidth: 500 }}>
                      <CardMedia
                        component="img"
                        height="194"
                        image={row?.image}
                        alt="Paella dish"
                      />

                      <CardContent>
                        <Typography
                          variant="h5"
                          component="h4"
                          className="catering-ttl cursor"
                        >
                          {row?.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          dangerouslySetInnerHTML={{
                            __html: row?.description,
                          }}
                        ></Typography>
                      </CardContent>
                      <CardActions disableSpacing>
                        {row?.quantity_options?.map((item) => (
                          <Grid item xs={4} key={item.id}>
                            <Grid
                              container
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <Typography
                                  variant="body1"
                                  className="item-name"
                                >
                                  {`${item?.servingPortion} ${item?.unit}`}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <CommoQuantityInput
                                  control={control}
                                  name={`${row?.title}quantity_options${item?.servingPortion}${item?.unit}`}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body1"
                                  className="item-price"
                                >
                                  {`Price: $${item?.amount}`}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="add-button"
                                  onClick={() => {
                                    handleAddItem({
                                      itemName: `${row?.title}(${item?.servingPortion}${item?.unit})`,
                                      itemQuantitiy: watch(
                                        `${row?.title}quantity_options${item?.servingPortion}${item?.unit}`
                                      ),
                                      _id: row?._id,
                                      amount: item?.amount,
                                    });
                                  }}
                                >
                                  Add
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid> */}

                <Stack spacing={2} marginTop={2} className="pagination">
                  <Pagination count={10} color="primary" />
                </Stack>
              </TableContainer>
            </Grid>
            <Grid item xs={3}>
              <CateringCart />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Catering;

import * as React from "react";
import { Link } from "react-router-dom";
import "./prasadam.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, CardHeader } from "react-bootstrap";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  DialogTitle,
} from "@mui/material";
import CateringCart from "../../components/common/Basket/CateringBasket";
import { useDispatch, useSelector } from "react-redux";
import { updatePrasadamCart } from "../../actions";
import toast from "react-hot-toast";
import { getApi, postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import CommoQuantityInput from "../../components/common/inputs/CommonQuantityInput";
import { Controller, useForm } from "react-hook-form";
import { Add } from "@mui/icons-material";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function Cafeteria() {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const prasadamArr = useSelector((state) => state.prasadamArr);
  const dispatch = useDispatch();

  const {
    watch,
    control,
    formState: { errors },
  } = useForm({});

  const getCafeteriaItems = async () => {
    const res = await postApi(apiEndPoints?.getAllCafeteriaItems);
    setData(res?.data);
  };

  React.useEffect(() => {
    getCafeteriaItems();
  }, []);

  const handleAddItem = (item) => {
    const existingCartItem = prasadamArr.find(
      (cartItem) => cartItem._id === item._id
    );

    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        itemQuantitiy: item?.itemQuantitiy,
      };
      dispatch(updatePrasadamCart(updatedItem));
      toast.success("Your puja/donation is updated");
    } else {
      const newItem = {
        ...item,
        itemName: item?.itemName,
        itemQuantitiy: item?.itemQuantitiy,
        _id: item?._id,
        amount: item?.amount,
      };
      dispatch(updatePrasadamCart(newItem));
      toast.success("Your puja/donation added successfully on puja basket");
    }
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Cafeteria</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Cafeteria</li>
        </ol>
      </div>

      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Services
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Cafeteria</span>
          </h1>
        </div>

        <div className="row">
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <div className="col">
                <nav className="navbar">
                  <form
                    className="container-fluid justify-content-start ng-untouched ng-pristine ng-valid"
                    noValidate
                  >
                    <button
                      className="tag-btn btn-outline-info me-2"
                      type="button"
                    >
                      Sweet
                    </button>
                    <button
                      className="tag-btn btn-outline-secondary me-2"
                      type="button"
                    >
                      Snack
                    </button>
                    <button
                      className="tag-btn btn-outline-primary me-2 active"
                      type="button"
                    >
                      Tiffin
                    </button>
                    <button
                      className="tag-btn btn-outline-warning me-2"
                      type="button"
                    >
                      Rice
                    </button>
                    <button
                      className="tag-btn btn-outline-dark me-2"
                      type="button"
                    >
                      South Indian
                    </button>
                    <button
                      className="tag-btn btn-outline-primary me-2"
                      type="button"
                    >
                      North Indian
                    </button>
                    <button
                      className="tag-btn btn-outline-success me-2"
                      type="button"
                    >
                      Pickles / Pachidi
                    </button>
                    <button
                      className="tag-btn btn-outline-secondary me-2"
                      type="button"
                    >
                      Beverages
                    </button>
                    <button
                      className="tag-btn btn-outline-danger me-2"
                      type="button"
                    >
                      Greens
                    </button>
                    <button
                      className="tag-btn btn-outline-info me-2"
                      type="button"
                    >
                      Others
                    </button>
                  </form>
                </nav>
              </div>
              <Grid container spacing={2} p={3}>
                {data?.map((row) => (
                  <Grid item xs={3} key={row.id}>
                    <Card sx={{ maxWidth: 500 }}>
                      <CardMedia
                        component="img"
                        height="160"
                        image={row?.image}
                        alt="Paella dish"
                      />

                      <CardContent>
                        <Typography
                          variant="h5"
                          component="h4"
                          className="catering-ttl2 cursor catering-ttl"
                        >
                          {row?.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          dangerouslySetInnerHTML={{
                            __html: row?.description,
                          }}
                        ></Typography>
                      </CardContent>
                      <CardActions disableSpacing>
                        <Grid item xs={12}>
                          <Grid
                            container
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <div className="d-flex wd justify-content-between">
                              <Grid item>
                                {/* <CommoQuantityInput
                                  control={control}
                                  name={`${row?.title}quantity`}
                                /> */}
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="add-button w120"
                                  onClick={() => {
                                    handleAddItem({
                                      itemName: `${row?.title}`,
                                      itemQuantitiy: watch(
                                        `${row?.title}quantity`
                                      ),
                                      _id: row?._id,
                                      amount: row?.price,
                                    });
                                  }}
                                >
                                  Add <Add />
                                </Button>
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant="body1"
                                  className="item-price cafeteria-price"
                                >
                                  {`$${row?.price}`}
                                </Typography>
                              </Grid>
                            </div>

                            {/* <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                className="add-button w120"
                                onClick={() => {
                                  handleAddItem({
                                    itemName: `${row?.title}`,
                                    itemQuantitiy: watch(
                                      `${row?.title}quantity`
                                    ),
                                    _id: row?._id,
                                    amount: row?.price,
                                  });
                                }}
                              >
                                Add <Add />
                              </Button>
                            </Grid> */}
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Stack spacing={2} marginTop={2} className="pagination">
                <Pagination count={10} color="primary" />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <CateringCart />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Cafeteria;

import React, { useEffect, useState } from "react";
import "./CateringBasket.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-hot-toast";
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from "@mui/material";

import {
  updatePrasadamCart,
  removeFromCart,
  removeFromPrasadamCart,
} from "../../../actions";
import { getApi } from "../../../ApiServices/api";
import { apiEndPoints } from "../../../ApiServices/config";
import ConsentDialog from "../ConsentDialog";
import CommoQuantityInput from "../inputs/CommonQuantityInput";
import { Add, Remove } from "@mui/icons-material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

function CateringCart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prasadamArr = useSelector((state) => state.prasadamArr);
  const [totalAmount, setTotalAmount] = useState(0);
  const [expanded, setExpanded] = useState({});
  const [donationData, setDonationsData] = useState([]);
  const [donationItem, setDonationItem] = useState(null);
  const [donationAmount, setDonationAmount] = useState(0);
  const [makeDonationChecked, setMakeDonationChecked] = useState(false);

  useEffect(() => {
    const total = prasadamArr.reduce((accumulator, item) => {
      return accumulator + item.amount * item?.itemQuantitiy;
    }, 0);

    setTotalAmount(total);
  }, [prasadamArr]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: newExpanded ? panel : false,
    }));
  };

  const handleEmptyPoojaBasket = () => {
    dispatch(updatePrasadamCart());
  };

  const handleProceedToPay = () => {
    navigate("/prasadm-checkout-services");
  };

  const getAllDonations = async () => {
    const response = await getApi(apiEndPoints.getAllDonations);
    if (response && response.status === 200) {
      setDonationsData(response?.data?.data);
      setDonationItem(response?.data?.data[0]);
    }
  };

  useEffect(() => {
    getAllDonations();
  }, []);

  const handleChildrenRemove = (_id) => {
    dispatch(removeFromPrasadamCart(_id));
    toast.success("Item removed from the Basket");
  };

  return (
    <div>
      <div className="border-bottom mb-4 pb-4 details-bg religious-service-product">
        <div className="d-flex justify-content-between mt-4">
          <h5 className="font-weight-semi-bold mb-3">Your Cart</h5>
          <div className="empty-btn" onClick={handleEmptyPoojaBasket}>
            Empty Cart
          </div>
        </div>

        <TableContainer component={Paper}>
          {prasadamArr.map((row) => (
            <Table aria-label="caption table" key={row.name}>
              <TableRow>
                <TableCell>
                  {row.itemName}
                  <Typography className="qty-sub">2 X 10.99</Typography>
                </TableCell>
                <TableCell align="right">
                  ${Number(row.amount) * Number(row?.itemQuantitiy)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Quantity</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      handleChildrenRemove(row?._id);
                    }}
                  >
                    <Remove className="decrease-btn" />
                  </IconButton>
                  {row?.itemQuantitiy}
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      handleChildrenRemove(row?._id);
                    }}
                  >
                    <Add className="increase-btn" />
                  </IconButton>
                </TableCell>
              </TableRow>
              {/* <TableRow className="bdr-top-btm">
                <TableCell>Grand Total</TableCell>
                <TableCell align="right">${totalAmount}</TableCell>
              </TableRow> */}
            </Table>
          ))}
        </TableContainer>
        <TableContainer component={Paper}>
          <TableRow>
            <TableCell align="left paper-btm">
              <ShoppingCartIcon style={{ marginRight: 3 }} />3 Items | $
              {totalAmount}
            </TableCell>
            <TableCell align="right paper-btm">
              <Button className="proceed-btn">Proceed</Button>
            </TableCell>
          </TableRow>
        </TableContainer>

        {/* <TableContainer component={Paper}>
          <Table aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell>Item Name</TableCell>
                <TableCell align="center">Qty</TableCell>
                <TableCell align="right">$ Price</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prasadamArr.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.itemName}
                  </TableCell>
                  <TableCell align="center">{row?.itemQuantitiy}</TableCell>
                  <TableCell align="right">
                    ${Number(row.amount) * Number(row?.itemQuantitiy)}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        handleChildrenRemove(row?._id);
                      }}
                    >
                      <Add />
                    </IconButton>

                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        handleChildrenRemove(row?._id);
                      }}
                    >
                      <Remove />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} className="font-b">
                  Grand Total
                </TableCell>
                <TableCell align="right" className="font-b">
                  ${totalAmount}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          className="mt-4"
          onClick={handleProceedToPay}
        >
          Proceed to pay
        </Button> */}
      </div>
    </div>
  );
}

export default CateringCart;

import React from "react";
import "./header.css";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import ShowLogin from "./ShowLogin";
import { Link } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from "@mui/icons-material/Pinterest";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

function Topheader() {
  return (
    <>
      <div className="container-fluid top-bg px-5 d-none d-lg-block">
        <div className="container">
          <div className="row gx-0 ">
            <div className="col-lg-6 text-center text-lg-start mb-lg-0">
              <div className="d-flex flex-wrap">
                <Link to="/" className="text-light me-4">
                  <LocationOnIcon className="top-icon" /> 8400 Durga Place,
                  Fairfax Station, VA, 22039
                </Link>
                <Link to="/" className="text-light me-0">
                  <MailIcon className="top-icon" /> contact@durgatemple.org
                </Link>
              </div>
            </div>
            <div className="col-lg-3 text-right text-lg-end">
              <ul className="footer-icon-list">
                <li>
                  <a
                    href="https://www.facebook.com/DurgaTemple"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FacebookIcon />
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.youtube.com/@DurgaTempleLive/streams"
                  >
                    <YouTubeIcon />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/durgatemple/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <InstagramIcon />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 text-center text-lg-end">
              <ShowLogin />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Topheader;
